import * as Sentry from "@sentry/nuxt";
const config = useRuntimeConfig();

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: config.public.SENTRY_DSN_PUBLIC as string | undefined,
  environment: import.meta.dev ? "development" : "production",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
